<template>
  <div>
    <!-- <div class="layout-authi grey lighten-3"> -->
    <!-- <div height class="layout-auth layout-auth-background"></div> -->

    <ServerError v-if="ServerError" />
    <div>
      <vue-element-loading :active="appLoading" is-full-screen width="80px" height="80px">
        <v-img src="../../assets/logo/Styloop_Logo1.png"></v-img>
        <span style="font-weight: bold" class="mt-5"> Loading...... </span>
      </vue-element-loading>
    </div>
    <v-snackbar v-model="showsnackbar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-container class="page-login">
      <v-row>
        <v-col cols="12">
          <v-card class="pa-3 __card mt-12" max-width="500">
            <v-card-title>
              <v-flex xs12 offset-4>
                <img src="../../assets/logo/Styloop_Logo1.png" height="35" class="pr-2" contain align-center />
              </v-flex>
              <v-flex xs12>
                <h1 class="display-1 page-login_title" style="text-align: center">
                  Admin Panel
                </h1>
              </v-flex>
            </v-card-title>
            <v-card-text>
              <!-- <v-row wrap>
              <v-col cols="6" class="d-flex justify-center"> -->
              <v-alert color="#171515" type="info"> Verify Phone </v-alert>
              <!-- </v-col>
             </v-row> -->
              <v-form ref="form" lazy-validation v-model="formValid">
                <!-- <v-row wrap>
              <v-col cols="6" class="d-flex justify-center"> -->
                <!-- <div style="display: flex; flex-direction: row;"> -->
                <v-layout wrap justify-center>
                  <v-flex px-2 xs12 text-center>
                    <span style="
                        font-size: 15px;
                        color: #7b7b7b;
                      ">Code is sent to</span>
                    <span style="
                        font-size: 15px;
                        color: #393c4a;
                      ">+91 {{ phoneno }}
                    </span>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex class="d-flex justify-center pt-4" xs12 sm12 md12 lg12 xl9>
                    <!-- <v-otp-input
                      input-classes="otp-input"
                      :numInputs="4"
                      separator
                      :shouldAutoFocus="true"
                      @on-complete="validateOTP"
                    /> -->
                    <v-otp-input length="4" v-model="otp" @on-complete="validateOTP"></v-otp-input>
                  </v-flex>
                </v-layout>
                <!-- </div> -->
                <!-- </v-col>   
              </v-row> -->
                <!-- v-on:keyup.enter="login" -->
                <v-layout wrap>
                  <v-flex xs12 pt-4 text-center>
                    <span style="
                        font-size: 15px;
                        color: #7b7b7b;
                      ">Didn't receive code? &nbsp;</span>

                    <span v-if="!otpexpired" style="
                        font-size: 15px;
                        color: #393c4a;
                      ">Please Wait {{ timeLeft }}</span>
                    <span v-if="otpexpired" style="
                        font-size: 15px;
                        color: #393c4a;
                        cursor: pointer;
                      " @click="sentOTP">Request again</span>
                  </v-flex>
                </v-layout>

                <v-layout v-if="msg2 && !otpexpired" py-4 wrap>
                  <v-flex text-center>
                    <span style="
                        font-size: 14px;
                        color: red;
                      ">{{ msg2 }}</span>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col class="d-flex justify-end pr-5" cols="10">
                  <v-btn dark color="#171515" @click="validateInput()" :loading="loading">
                    <v-icon>mdi-arrow-right-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- </div> -->
  </div>
</template>

<script>
// import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
import store from "../../store";
export default {
  props: ["pageData"],
  data() {
    return {
      phoneno: this.pageData.phoneno,
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
      otp: "",
      timeLeft: "00:00",
      intervalTimer: null,
      otpexpired: false,
      loading: false,
      formValid: false,
    };
  },
  //   computed: {
  //     prefix() {
  //       return ''
  //     }
  //   },
  beforeMount() {
    this.setTime(60);
  },
  methods: {
    validateOTP(value) {
      this.otp = value;

      this.validateInput();
    },
    validateInput() {
      this.login();
    },
    login() {
      // this.$router.push("/")
      this.appLoading = true;
      var userData = {};
      userData["phone"] = this.pageData.phoneno;
      userData["otp"] = this.otp;
      axios({
        method: "POST",
        url: "/user/weblogin",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            store.commit("loginUser", true);
            store.commit("userData", response.data.userdata);
            console.log(response.data.data);
            localStorage.setItem("token", response.data.data.token);
            if (response.data.data.type == "Admin") {
              //   store.commit("userType", true);
              this.$router.push("/");
            }
            else if (response.data.data.type == "SubAdmin") {
              this.$router.push("/");
            }
            else {
              this.msg2 = "Unauthorised access";
            }
          } else {
            this.msg2 = response.data.msg;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    sentOTP() {
      var userData = {};
      userData["phone"] = this.pageData.phoneno;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/getsms",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.setTime(60);
            this.otpexpired = false;
            this.msg = "OTP Sent Successfully";
            this.showsnackbar = true;
          } else {
            this.msg = "Something Went Wrong";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    setTime(seconds) {
      clearInterval(this.intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.countdown(end);
    },
    countdown(end) {
      this.intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);
        if (secondsLeft == 0) this.otpexpired = true;
        if (secondsLeft < 0) {
          clearInterval(this.intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;
      var seconds1 = seconds;
      if (seconds < 10) seconds1 = "0" + seconds;
      this.timeLeft = "0" + minutes + " : " + seconds1;
    },
  },
};
</script>

<style lang="sass" scoped>
.__card
  max-width: 600px
  margin: 0 auto

.layout-auth

  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0

.layout-authi

  height: 100%
  width: 100%
</style>
<style>
.layout-auth-background {
  background-color: #171515;
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.layout-authi {
  height: 100%;
  width: 100%;
}

.otp-input {
  width: 35px !important;
  height: 35px !important;
  padding: 5px !important;
  margin: 0 10px;
  font-size: 20px !important;
  border: 1px solid #d6d6d6;
  outline: 0;
  border-width: 1px 1px 1px;
  text-align: "center";
  padding-left: 12px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #c8c8c8;
}

.separator::before {
  margin-right: 0.25em;
}

.separator::after {
  margin-left: 0.25em;
}

@media screen and (max-width: 768px) {
  .otp-input {
    width: 25px !important;
    height: 25px !important;
    padding: 4px !important;
    margin: 0 10px;
    font-size: 15px !important;
    border: 1px solid #d6d6d6;
    outline: 0;
    border-width: 1px 1px 1px;
    text-align: "center";
    padding-left: 12px;
  }
}
</style>