<template >
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <!-- <div class="layout-authi grey lighten-3"> -->
    <v-snackbar v-model="showsnackbar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-container class="page-login">
      <v-row class="">
        <v-col>
          <v-card class="pa-3 page-login__card mt-12" max-width="500">
            <v-card-title>
              <v-flex xs12 offset-4>
                <img
                  src="../../assets/logo/Styloop_Logo1.png"
                  height="35"
                  class="pr-2"
                  contain
                  align-center
                />
              </v-flex>
              <v-flex xs12>
                <h1
                  class="display-1 page-login_title"
                  style="text-align: center"
                >
                  Admin Panel
                </h1>
              </v-flex>
            </v-card-title>
            <v-card-text>
              <v-alert color="#171515" type="info">
                Use Mobile number to login in
              </v-alert>
              <!-- </v-col>
             </v-row> -->
              <!-- <v-row wrap>
              <v-col cols="6" class="d-flex justify-center"> -->
              <v-form @submit.prevent="validateInput">
                <v-text-field
                  dense
                  type="number"
                  v-model="phoneno"
                  placeholder="Mobile number"
                  prepend-inner-icon="mdi-phone"
                  autocomplete="off"
                  required
                  outlined
                >
                  <template slot="prepend-inner">
                    <v-layout pt-1 wrap>
                      <v-flex lg12 align-self-end> +91 </v-flex>
                    </v-layout>
                  </template></v-text-field
                >
              </v-form>
              <v-layout v-if="msg2" py-4 wrap>
                <v-flex text-center>
                  <span
                    style="
                      font-family: poppinslight;
                      font-size: 14px;
                      color: red;
                    "
                    >{{ msg2 }}</span
                  >
                </v-flex>
              </v-layout>
              <!-- </v-col>
              </v-row> -->
              <!-- v-on:keyup.enter="login" -->
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col class="d-flex justify-end pr-5" cols="10">
                  <v-btn dark color="#171515" @click.prevent="validateInput">
                    <v-icon>mdi-arrow-right-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PageLogin",
  data() {
    return {
      phoneno: null,
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
    };
  },

  methods: {
    validateInput() {
      if (!this.phoneno) {
        this.msg = "Please enter your number";
        this.showsnackbar = true;
        return;
      } else if (this.phoneno.length != 10) {
        this.msg = "Please enter valid number";
        this.showsnackbar = true;
      } else this.login();
    },
    login() {
      this.appLoading = true;
      var data = {};
      data["phone"] = this.phoneno;

      axios({
        method: "POST",
        url: "/user/getsms",
        data: data,
      })
        .then((response) => {
          this.appLoading = false;
          if (
            response.data.status &&
            !response.data.isnew &&
            response.data.type == "Admin"
          ) {
            this.$emit("stepper", {
              phoneno: this.phoneno,
              response: response.data,
              winStep: 1,
            });
          }
            else if (response.data.status && !response.data.isnew && response.data.type == "SubAdmin") {
            this.$emit("stepper", {
              phoneno: this.phoneno,
              response: response.data,
              winStep: 1,
            });
          }
          else {
            this.msg2 = "Unauthorised access";
          }
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.page-login
  &__card
    max-width: 600px
    margin: 0 auto

.layout-auth

  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0

.layout-authi

  height: 100%
  width: 100%
</style>
<style scoped>
.layout-auth-background {
  background-color: #171515;
}
.unstyled
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input::-webkit-outer-spin-button,
.unstyled
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
</style>